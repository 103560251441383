const getDefaultImageUrls = (imageConfigurations) => {
  const imageUrls = new Proxy({}, {
    get() {
      return 'https://fashionunited.com/r/,/https://media.fashionunited.com/media/logos/defaultImageBlackHighonGrey.svg';
    },
  });

  return imageConfigurations.map(
    (imageConfiguration) => imageUrls[imageConfiguration.width],
  );
};

export default getDefaultImageUrls;
